import axios from 'axios';

// Set config defaults url
const { NODE_ENV: ENV } = process.env;

export const API = ENV === 'production' ? axios.create({
  baseURL: 'https://admin.flowently.com/api/'
  // baseURL: ' https://flowently-booking.whellostudio.id/api/'
}) : axios.create({
  // baseURL: ' https://flowently-booking.whellostudio.id/api/'
  // baseURL: 'https://admin.flowently.com/api/'
  baseURL: 'http://localhost:3001/'
});

export const DOMAIN = ENV === 'production' ? 
  // 'https://flowently.whellostudio.id'
  'https://admin.flowently.com'
  : 'http://localhost:3000';

export const SERVER_DOMAIN = ENV === 'production' ? 
  // 'https://flowently.whellostudio.id'
  'https://admin.flowently.com/api'
  : 'http://localhost:3001';